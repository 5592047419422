<template>
  <Headline
    :headline="headline"
    :subline="subline"
    :has-decoration="hasDecoration"
    :align-text="alignText"
    :level="level"
    :stroke-width="strokeWidth"
    :stroke-color="strokeColor"
    :logo-color-main="logoColorMain"
    :logo-color-second="logoColorSecond"
    :logo-color="logoColor"
    :text-color="textColor"
    :classes="classes"
    :container-classes="containerClasses"
    :deco-container-classes="decoContainerClasses"
    :width="width"
    :mobile-full-deco-width="mobileFullDecoWidth"
    :testid="testid ?? 'headline'"
  />
</template>
<script setup lang="ts">
import type { EHeadlineSize } from '~/@types/headline';

export interface Props {
  headline: string;
  subline?: string;
  hasDecoration?: boolean;
  alignText?: string;
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | string | EHeadlineSize;
  strokeWidth?: number;
  strokeColor?: string;
  logoColor?: string;
  logoColorMain?: string;
  logoColorSecond?: string;
  textColor?: string;
  classes?: string;
  containerClasses?: string;
  decoContainerClasses?: string;
  width?: string;
  mobileFullDecoWidth?: boolean;
  testid?: string;
}

withDefaults(defineProps<Props>(), {
  headline: '',
  subline: null,
  hasDecoration: false,
  alignText: 'center',
  level: 'h1',
  strokeWidth: 1,
  logoColor: null,
  strokeColor: null,
  logoColorMain: null,
  logoColorSecond: null,
  textColor: '',
  classes: '',
  containerClasses: null,
  decoContainerClasses: '',
  width: 'w-fit',
  maxWidth: 'max-w-screen-md',
  mobileFullDecoWidth: false,
});

const Headline = defineAsyncComponent(() => {
  if (useSiteIdent() === SiteIdent.default) {
    return import('./vdv/headline-dynamic.vue') as any; //deliberate any use: components need to be refactored to prevent a type mismatch
  } else {
    return import('./minilu/headline-dynamic.vue');
  }
});
</script>
